import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const p = localStorage.getItem("cart_items");
    if (p !== null && p !== undefined) {
      const pItems = JSON.parse(p);
      setCartItems(pItems);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cart_items", JSON.stringify(cartItems));
  }, [cartItems]);

  const addCartItem = (item) => {
    setCartItems((old) => {
      const existItem = old.find((x) => x.product.ID === item.product.ID);
      if (existItem) {
        return old.map((x) =>
          x.product.ID === existItem.product.ID
            ? { product: item.product, count: existItem.count + item.count }
            : x
        );
      } else {
        return [...old, item];
      }
    });
  };
  const removeCartItem = (item) => {
    setCartItems((old) => old.filter((x) => x.product.ID !== item.product.ID));
  };
  const updateCartItem = (item) => {
    setCartItems((old) =>
      old.map((x) => (x.product.ID === item.product.ID ? item : x))
    );
  };
  const emptyCart = () => {
    setCartItems([]);
  };
  return (
    <CartContext.Provider
      value={{
        cartItems,
        addCartItem,
        removeCartItem,
        updateCartItem,
        emptyCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
