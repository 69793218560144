import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";
import { OrderForm } from "../component/OrderForm";

export function OnlineOrder() {
  return (
    <MainLaylout>
      <HeroHeader
        title={"Online Order Form"}
        breadcrumb={[
          { link: "/", name: "Home" },
          { link: "/online_order", name: "Online Order" },
        ]}
      />
      <div class="whole-wrap">
        <div class="container box_1170">
          <OrderForm />
        </div>
      </div>
    </MainLaylout>
  );
}
