import { MainLaylout } from "../component/MainLayout";
import { HeroHeader } from "../component/HeroHeader";
import { useParams, useNavigate } from "react-router-dom";
import { getProductById } from "../api_client/fetch_client";
import { useEffect, useState, useContext } from "react";
import React from "react";
import {
  Box,
  Button,
  Center,
  Grid,
  useDisclosure,
  Text,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spacer,
} from "@chakra-ui/react";
import { AppContext } from "../context/AppContext";
import { ImageModal } from "../component/ImageModal";
import { YouMayLike } from "../component/YouMayLike";
import { CartContext } from "../context/CartContext";
import { ToastContext } from "../context/ToastContext";

function ProductPanel({ myProd, imageBase }) {
  const [cnt, setCnt] = useState(1);
  const { addCartItem } = useContext(CartContext);
  const { showToast } = useContext(ToastContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const AddToCart = (p) => {
    const reduced = { ...p };
    delete reduced.DESCRIPTION;
    addCartItem({ product:  reduced , count: cnt });
    showToast("Success", `${p.NAME} is added into the cart`, "success");
  };
  return (
    <Grid templateColumns="60% 40%" gap={4}>
      <Box p={4} bg="gray.100">
        <Center>
          <Box
            as="img"
            src={`${imageBase}/${myProd.IMAGE}`}
            alt={myProd.NAME}
            maxH={500}
            onClick={onOpen}
            cursor={"pointer"}
          />
        </Center>
        <ImageModal
          imgUrl={`${imageBase}/${myProd.IMAGE}`}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
      <Box p={4} bg="gray.100">
        {/* {parse(myProd.DESCRIPTION)} */}
        <Text>{myProd.NAME}</Text>
        <Text>{myProd.COLOR}</Text>
        {myProd.CAT_ID !== 18 && <HStack>
          <NumberInput
            defaultValue={1}
            min={1}
            max={99}
            step={1}
            value={cnt}
            onChange={(v) => setCnt(+v)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Spacer />
          <Button
            w={150}
            // h={2}
            bg={"#ff2020"}
            p={5}
            color={"#fff"}
            fontSize={16}
            fontWeight={300}
            colorScheme="red"
            onClick={() => AddToCart(myProd)}
          >
            ADD TO CART
          </Button>
          <Spacer w={20} />
        </HStack>}
        <Spacer />
        <Box dangerouslySetInnerHTML={{ __html: myProd.DESCRIPTION }} />
      </Box>
    </Grid>
  );
}

export function Product() {
  const [prodData, setProdData] = useState(null);
  const [breadcrumb, setBreadCrumb] = useState([]);
  let param = useParams();
  const navigate = useNavigate();
  const { imageBaseUrl } = useContext(AppContext);

  useEffect(() => {
    const updateBreadCrumb = ({
      CAT_ID,
      CAT_NAME,
      SUBCAT_ID,
      SUBCAT_NAME,
      ID,
    }) => {
      setBreadCrumb([
        { link: "/", name: "Home" },
        { link: `/category/${CAT_ID}`, name: CAT_NAME },
        { link: `/category/${CAT_ID}/${SUBCAT_ID}`, name: SUBCAT_NAME },
        { link: `/product/${ID}`, name: "Product Details" },
      ]);
    };
    const loadProductById = async (prdid) => {
      const rsp = await getProductById(prdid);
      if (!rsp.success) {
        // throw new Error("loadProductById failed");
        console.debug("loadProductById failed");
        navigate("/");
      }
      if (rsp.results.length !== 1) {
        console.debug("rsp.results.length is not 1");
        navigate("/");
      }
      setProdData(rsp.results[0]);
      updateBreadCrumb(rsp.results[0]);
    };
    if (param.prdid === undefined || param.prdid === null) {
      navigate("/");
    }
    loadProductById(param.prdid);
  }, [param, navigate]);

  return (
    <MainLaylout>
      <HeroHeader title={"Product Details"} breadcrumb={breadcrumb} />
      <div class="container">
        {prodData !== null && (
          <ProductPanel myProd={prodData} imageBase={imageBaseUrl} />
        )}
      </div>
      {prodData !== null && <YouMayLike {...prodData} />}
    </MainLaylout>
  );
}
