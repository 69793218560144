import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import {
  InputControl,
  TextareaControl,
} from "./ContactForm";
import { useContext, useRef } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import { IoMdRemoveCircle } from "react-icons/io";
import { addOrderForm } from "../api_client/fetch_client";
import { ToastContext } from "../context/ToastContext";
import { CartContext } from "../context/CartContext";
import { AppContext } from "../context/AppContext";

const ItemTable = () => {
  const { cartItems, removeCartItem, updateCartItem, emptyCart } =
    useContext(CartContext);

  const { imageBaseUrl } = useContext(AppContext);
  const removeItem = (x) => {
    removeCartItem(x);
  };

  const onCountChange = (x) => {
    updateCartItem(x);
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {/*<Th>#</Th>*/}
            <Th>NO</Th>
            <Th>LINK</Th>
            <Th>PRODUCT</Th>
            <Th isNumeric>Quantity</Th>
            <Th>
              <IconButton
                icon={<IoMdRemoveCircle />}
                onClick={() => emptyCart()}
                variant={"outline"}
                colorScheme={"gray"}
                aria-label={"remove_all"}
                size={"md"}
                // disabled={true}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {cartItems &&
            cartItems.map((item, index) => (
              <Tr key={index}>
                {/*<Td>{item.id}</Td>*/}
                <Td>
                  <Text>{index + 1}</Text>
                </Td>
                <Td>
                  <a href={`/product/${item.product.ID}`} target="__blank">
                    <Image
                      src={`${imageBaseUrl}/${item.product.IMAGE}`}
                      maxH={"100"}
                      maxW={"100"}
                    ></Image>
                  </a>
                </Td>
                <Td>
                  <VStack align={"start"} spacing={0}>
                    <HStack spacing={4}>
                      <Text fontWeight={"bold"}>Name:</Text>
                      <Text>{item.product.NAME}</Text>
                    </HStack>
                    <HStack spacing={4}>
                      <Text fontWeight={"bold"} fontSize={"sm"}>
                        Color:
                      </Text>
                      <Text fontSize={"sm"}>
                        {item.product.COLOR ? item.product.COLOR : "N/A"}
                      </Text>
                    </HStack>
                  </VStack>
                </Td>
                <Td>
                  <NumberInput
                    step={1}
                    defaultValue={1}
                    min={1}
                    max={99}
                    size={"sm"}
                    value={item.count}
                    onChange={(v) => onCountChange({ ...item, count: +v})}
                  >
                    <NumberInputField
                      colorScheme="red"
                      isRequired={true}
                      focusBorderColor="#ff2020"
                      size={"sm"}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {/* <TableNumberInputControl
                    item_id={item.count}
                    name={"count"}
                    value={item.quantity}
                    required={true}
                    onChange={()=>itemCountOnChange}
                  /> */}
                </Td>
                {/* <Td>
                  <TableInputControl
                    item_id={item.id}
                    name={"remarks"}
                    value={item.remarks}
                    required={false}
                    onChange={itemOnChange}
                  />
                </Td> */}
                <Td>
                  <IconButton
                    icon={<IoMdRemoveCircle />}
                    onClick={() => removeItem(item)}
                    variant={"outline"}
                    colorScheme={"gray"}
                    aria-label={"remove_item"}
                    size={"sm"}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
function FormRender({
  handleSubmit,
  form,
  submitting,
  pristine,
  items,
  setItems,
  formRef,
}) {
  const {cartItems} = useContext(CartContext);
  return (
    <form
      p={4}
      ref={formRef}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="1px 1px 3px rgba(0,0,0,0.3)"
      onSubmit={handleSubmit}
      flex={1}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={0}>
        <GridItem colSpan={2} px={1} w={"full"}>
          <HStack
            align={"center"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text textAlign={"center"} fontSize={"lg"} justify={"center"}>
              ORDER ITEMS
            </Text>
          </HStack>
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <ItemTable items={items} setItems={setItems} />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <Box
            width="100%" // Full width
            height="3px" // Height of the bar
            bg="red.500" // Chakra color scale (e.g., green)
            my={4} // Optional: margin around the bar
            mt={6}
          />
        </GridItem>
        <GridItem colSpan={2} px={1}>
          <Text textAlign={"center"} fontSize={"lg"} justify={"center"} mb={5}>
            CHECK OUT
          </Text>
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="company" label="COMPANY" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="abn" label="ABN" />
        </GridItem>
        {/*row 2*/}
        <GridItem colSpan={1} px={1}>
          <InputControl name="phone" label="PHONE" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="order_by" label="ORDER BY" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <Text textAlign={"center"} fontSize={"lg"}>
            DELIVER TO
          </Text>
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl
            name="deliver_company"
            label="COMPANY(If different from above)"
            placeholder="COMPANY"
            required={false}
          />
        </GridItem>

        <GridItem colSpan={1} px={1}>
          <InputControl name="contact_person" label="CONTACT PERSON" />
        </GridItem>
        <GridItem colSpan={1} px={1}>
          <InputControl name="contact_number" label="CONTACT NUMBER" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl name="contact_email" label="EMAIL" />
        </GridItem>

        <GridItem colSpan={2} px={1}>
          <InputControl name="deliver_address" label="DELIVER ADDRESS" />
        </GridItem>
        <GridItem colSpan={2} px={1}>
          <TextareaControl
            name="special_request"
            label="SPECIAL REQUEST"
            isRequired={false}
          />
        </GridItem>
      </Grid>
      <Flex flex={1}>
        <Turnstile siteKey="0x4AAAAAAAeESYGmwSqk-ejv" />
        <Spacer />
        <ButtonGroup
          spacing={4}
          // flex={1}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Button
            isLoading={submitting}
            loadingText="Submitting"
            // variantColor="red"
            // colorScheme="red"
            h={"50px"}
            w={"100px"}
            borderRadius={0}
            type="submit"
            bg={"#ff2020"}
            color={"white"}
            isDisabled={cartItems.length<=1}
          >
            Submit
          </Button>
          <Button
            // variantColor="red"
            colorScheme="red"
            variant="outline"
            h={"50px"}
            w={"100px"}
            onClick={form.reset}
            borderRadius={0}
            isDisabled={submitting || pristine}
          >
            Reset
          </Button>
        </ButtonGroup>
      </Flex>
    </form>
  );
}

export function OrderForm() {
  const { showToast } = useContext(ToastContext);
  const { cartItems, emptyCart } = useContext(CartContext);
  const {imageBaseUrl} = useContext(AppContext);
  const formRef = useRef(null);
  const validate = (values) => {
    const errors = {};
    return errors;
  };
  const onSubmit = async (values) => {
    if (
      window.location.hostname==="localhost" || 
      (formRef.current &&
      formRef.current.querySelector('[name="cf-turnstile-response"]') &&
      formRef.current.querySelector('[name="cf-turnstile-response"]').value)
    ) {
      const payload = {
        ...values,
        items: JSON.stringify(cartItems),
        "img_base_url": imageBaseUrl,
        "cf-turnstile-response": formRef.current.querySelector(
          '[name="cf-turnstile-response"]'
        ).value,
      };

      console.debug("onSubmit payload:", payload);
      const rsp = await addOrderForm(payload);

      console.debug("onSubmit response:", rsp);
      if (!rsp || !rsp.success) {
        showToast("Error", rsp.message, "error");
      } else {
        showToast(
          "Success",
          "Thank you for the submission. \n We'll reach you out soon.",
          "success"
        );
        setTimeout(() => {
          emptyCart()
          window.location.reload();
        }, 2000);
      }
    } else {
      showToast("Error", "Verify you are human.", "error");
    }
  };
  return (
    <HStack pl={10} alignItems={"flex-start"}>
      <Box w={{ sm: 600, lg: 1000 }} p={4} m="20px auto">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <FormRender
              handleSubmit={handleSubmit}
              form={form}
              submitting={submitting}
              pristine={pristine}
              // items={items}
              // setItems={setItems}
              formRef={formRef}
            />
          )}
        />
      </Box>
    </HStack>
  );
}
