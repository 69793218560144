import Slider from "react-slick";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { CartContext } from "../context/CartContext";
import { useBreakpointValue } from "@chakra-ui/react";
import { ToastContext } from "../context/ToastContext";

export function SingleProduct({ product }) {
  const ctx = useContext(AppContext);
  const { showToast } = useContext(ToastContext);
  const { addCartItem } = useContext(CartContext);

  const AddToCard = (p) => {
    const pp = {...p}
    delete pp.DESCRIPTION;
    addCartItem({ product: pp, count: 1 });
    // show toast

    showToast("Success", `${p.NAME} is added into the cart`, "success");
  };
  return (
    <div class="properties pb-30">
      <div class="properties-card">
        <div class="properties-img">
          <a
            href={product.FIXED === 1 ? product.URL : `/product/${product.ID}`}
            target="__blank"
          >
            <img src={`${ctx.imageBaseUrl}/${product.IMAGE}`} alt="" />
          </a>
          <div class="socal_icon">
            {product.FIXED !== 1 && product.CAT_ID !== 18 && (
              <span onClick={() => AddToCard(product)}>
                <i class="ti-shopping-cart"></i>
              </span>
            )}

            {/* <a href="index.html#">
              <i class="ti-heart"></i>
            </a> */}
            <a
              href={
                product.FIXED === 1 ? product.URL : `/product/${product.ID}`
              }
              target="__blank"
            >
              <i class="ti-zoom-in"></i>
            </a>
          </div>
        </div>
        <div class="properties-caption properties-caption2">
          <h3>
            <a
              href={
                product.FIXED === 1 ? product.URL : `/product/${product.ID}`
              }
              target="__blank"
            >
              {product.NAME}
            </a>
          </h3>
          <div class="properties-footer">
            <div class="price" style={{ display: "none" }}>
              <span>
                $98.00 <span>$120.00</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function ProductsSlider({ products }) {
  const slidesToShow = useBreakpointValue({
    base: 1, // 1 slide for small screens
    sm: 2, // 2 slides for small-medium screens
    md: 3, // 3 slides for medium screens
    lg: 4, // 4 slides for large screens
    xl: 5, // 5 slides for extra-large screens
  });
  const settings = {
    // dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Slider {...settings}>
      {/* <div class="latest-items-active"> */}
      {products.map((p, idx) => (
        <SingleProduct product={p} key={idx} />
      ))}
      {/* </div> */}
    </Slider>
  );
}
