import { MainLaylout } from "../component/MainLayout";
import { useContext, useState, useRef } from "react";
import { userTradeLogin } from "../api_client/trade_client";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "@chakra-ui/react";
import { Turnstile } from "@marsidev/react-turnstile";

export function TradeLogin() {
  const toast = useToast();
  const { updateCurrentTradeUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [allowed, setAllowed] = useState(true);

  const formRef = useRef(null);
  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAllowed(false);
    // console.debug("onSubmit:", username, password);
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    if (
      formRef.current &&
      formRef.current.querySelector('[name="cf-turnstile-response"]') &&
      formRef.current.querySelector('[name="cf-turnstile-response"]').value
    ) {
      formData.append(
        "cf_token",
        formRef.current.querySelector('[name="cf-turnstile-response"]').value
      );
    } else {
      toast({
        title: "Login Failed",
        description: "Verify you are human.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
      // showToast("Error", "Verify you are human.", "error");
    }

    const rsp = await userTradeLogin(formData);
    console.debug("onsubmit, userLogin: ", rsp);
    if (rsp.success) {
      updateCurrentTradeUser(rsp.result);
    } else {
      toast({
        title: "Login Failed",
        description: "Username or Password doesn't match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setAllowed(true);
  };
  return (
    <MainLaylout>
      <div class="login-form-area">
        <form onSubmit={handleSubmit} ref={formRef}>
          <div class="login-form">
            <div class="login-heading">
              <span>Trade Customer Login</span>
              <p>Enter Login details to get access</p>
            </div>

            <div class="input-box">
              <div class="single-input-fields">
                <label>Username or Email Address</label>
                <input
                  type="text"
                  placeholder="Username / Email address"
                  value={username}
                  onChange={onUsernameChange}
                  disabled={!allowed}
                />
              </div>
              <div class="single-input-fields">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={onPasswordChange}
                  disabled={!allowed}
                />
              </div>
              {/*<div class="single-input-fields login-check">*/}
              {/*  <input*/}
              {/*    type="checkbox"*/}
              {/*    id="fruit1"*/}
              {/*    name="keep-log"*/}
              {/*    disabled={!allowed}*/}
              {/*  />*/}
              {/*  <label for="fruit1">Keep me logged in</label>*/}
              {/*  <a href="login.html#" class="f-right">*/}
              {/*    Forgot Password?*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>

            <div class="login-footer">
              {/*<p>*/}
              {/*  Don’t have an account? <a href="register.html">Sign Up</a> here*/}
              {/*</p>*/}

              <Turnstile siteKey="0x4AAAAAAAeESYGmwSqk-ejv" />
              <button class="submit-btn3" type={"submit"} disabled={!allowed}>
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </MainLaylout>
  );
}
